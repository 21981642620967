@charset "UTF-8";


.clearfix {
  *zoom: 1;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
} // @extend .clearfix;

@mixin clearfix{
    &:before,&:after { content: ""; display: table;}
    &:after {clear: both;}
    zoom: 1;
}

// vender pre fix
$set-prefix: '' , -moz- , -webkit-, -o-, -ms-;
@mixin PropertySetPrefix($name, $value) {
    @each $prefix in $set-prefix {
        #{$prefix}#{$name}: $value;
    }
} // @include PropertySetPrefix(border-radius, 5px);

@mixin flexbox {
	display:-webkit-box;
	display:-moz-box;
	display:-ms-flexbox;
	display:-webkit-flex;
	display:flex;
} // @include flexbox();


@mixin flex($values) {
	-webkit-box-flex: $values;
	-moz-box-flex:  $values;
	-ms-flex:  $values;
	-webkit-flex:  $values;
	flex:  $values;
} // @include flex(1.0);

@mixin flex-wrap($values) {
  -webkit-box-flex-wrap: $values;
  -moz-box-flex-wrap: $values;
  -ms-flex-wrap: $values;
  -webkit-flex-wrap: $values;
  flex-wrap: $values;
}

@mixin flex-direction($values) {
  -webkit-flex-direction: $values;
  -moz-flex-direction: $values;
  -ms-flex-direction: $values;
  flex-direction: $values;
}


@mixin flex-order($values) {
  -webkit-box-ordinal-group: $values;
    -moz-box-ordinal-group: $values;
    -webkit-flex-order: $values;
    -moz-flex-order: $values;
    -webkit-order: $values;
    -moz-order: $values;
    order: $values;
} // @include flex-order(1);

@mixin justify-content($values) {
    -webkit-justify-content: $values;
    -moz-justify-content: $values;
    
    @if $values == space-between {
      -ms-flex-pack: justify;
    }
    @else if $values == space-around {
      -ms-flex-pack: justify;
    }
    @else if $values == flex-start {
      -ms-flex-pack: start;
    }
    @else {
      -ms-flex-pack: $values;
    }
    justify-content: $values;
}

@mixin align-items($values) {
  -webkit-align-items: $values;
    -moz-align-items: $values;
    -ms-align-items: $values;
    align-items: $values;
} // @include justify-content(1);


@mixin align-self($values) {
  -webkit-align-self: $values;
    -moz-align-self: $values;
    -ms-align-self: $values;
    align-self: $values;
} // @include justify-content(1);



@mixin font-size($size) {
	font-size:$size;
	font-size: ($size / 14px) * 1rem;
} // @include font-size(14px);


@mixin vertical-align {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
} // @include vertical-align();

// text-shadow
@mixin text-shadow($value...) {
    -webkit-text-shadow: $value;
    -moz-text-shadow: $value;
    -ms-text-shadow: $value;
    text-shadow: $value;
}

@mixin box-shadow($value...) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    -ms-box-shadow: $value;
    box-shadow: $value;
}

@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation == vertical {
    // vertical
    background: -moz-linear-gradient(top,  $start-color 0%, $end-color 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start-color), color-stop(100%,$end-color));
    background: -webkit-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: -o-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: -ms-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: linear-gradient(to bottom,  $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
  }
  @else if $orientation == horizontal {
    // horizontal
    background: -moz-linear-gradient(left,  $start-color 0%, $end-color 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start-color), color-stop(100%,$end-color));
    background: -webkit-linear-gradient(left,  $start-color 0%,$end-color 100%);
    background: -o-linear-gradient(left,  $start-color 0%,$end-color 100%);
    background: -ms-linear-gradient(left,  $start-color 0%,$end-color 100%);
    background: linear-gradient(to right,  $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
  }
  @else {
    // radial
    background: -moz-radial-gradient(center, ellipse cover,  $start-color 0%, $end-color 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$start-color), color-stop(100%,$end-color));
    background: -webkit-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
    background: -o-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
    background: -ms-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
    background: radial-gradient(ellipse at center,  $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
  }
} // @include gradient(#ff00ff, #ff00cc, vertical);


@mixin vertical-gradient($start-color, $start-pos, $middle-color, $middle-pos, $end-color, $end-pos) {
  background: $start-color;
  background: -moz-linear-gradient(top,  $start-color $start-pos, $middle-color $middle-pos, $end-color $end-pos);
  background: -webkit-gradient(linear, left top, left bottom, color-stop($start-pos,$start-color),color-stop($middle-pos,$middle-color), color-stop($end-pos,$end-color));
  background: -webkit-linear-gradient(top,  $start-color $start-pos, $middle-color $middle-pos, $end-color $end-pos);
  background: -o-linear-gradient(top,  $start-color $start-pos,$middle-color $middle-pos,$end-color $end-pos);
  background: -ms-linear-gradient(top,  $start-color $start-pos,$middle-color $middle-pos,$end-color $end-pos);
  background: linear-gradient(to bottom,  $start-color $start-pos,$middle-color $middle-pos,$end-color $end-pos);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
  
} // @include vertical-gradient(#ff00ff 0%, #ffffff 50%, #ff00cc 100%);


@mixin column($num,$margin:20) {
    @include clearfix;
    //margin-left:#{-$margin}px;

    $margin_num     :$num - 1;
    $margin_width   :#{$margin * $num}px;
  $ie_fix     :100% / $num;
  
    > .col,
    > li {
        float: left;
        //margin-left:#{$margin}px;
    
    width:calc((100% - #{$margin_width}) / #{$num});
        width: $ie_fix;
    //width:-webkit-calc((100% - #{$margin_width}) / #{$num});
    width:-webkit-calc(100% / #{$num});
    width:-moz-calc(100%  / #{$num});
    
    }
} //@include column(2,40);




@mixin icon-centering {
	position: relative;
	> *,
	&:after,
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		display: block;
	}
}


@mixin inline-block {
	letter-spacing: -.40em;
	> li {
		letter-spacing: normal;
		display: inline-block;
	}
	@-moz-document url-prefix() {
		word-spacing: 0;
	}
	

}


// animation 
@mixin keyframes($value) {
    @-webkit-keyframes #{$value} {
        @content;
    }
    @-moz-keyframes #{$value} {
        @content;
    } 
    @keyframes #{$value} {
        @content;
    }
}


@mixin animation($value) {
  -webkit-animation: $value;
  -moz-animation: $value;
  animation: $value;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}



// icon fonts
@mixin icon-font{
    /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



// ol list re-design

@mixin ol-redesign {
    counter-reset: li;
    list-style: none;
    padding-left: 0;
    
    > li {
        position: relative;
        
        &:before {
            content: counter(li) "";
            counter-increment: li;
            display: inline-block;
        }
    }
}


@mixin arrow-b($color) {
    position: absolute;
    content: "";
    display: block;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: $color transparent transparent transparent;
}



@mixin balloon-arrow($direction, $duration, $color) {
    position: absolute;
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    @if $direction == top {
        top: -$duration;
        left: 50%;
        margin-left: -$duration;
        border-width: 0 $duration $duration $duration;
        border-color: transparent transparent $color transparent;
    }
    @if $direction == right {
        right: -$duration;
        top: 50%;
        margin-top: -$duration;
        border-width: $duration 0 $duration $duration;
        border-color: transparent transparent transparent $color;
    }
    @if $direction == bottom {
        bottom: -$duration;
        left: 50%;
        margin-left: -$duration;
        border-width: $duration $duration 0 $duration;
        border-color: $color transparent transparent transparent;
    }
    @if $direction == left {
        left: -$duration;
        top: 50%;
        margin-top: -$duration;
        border-width: $duration $duration $duration 0 ;
        border-color: transparent $color transparent transparent;
    }
}

@mixin pseudo {
  position: absolute;
  display: block;
  content: "";
}

@mixin mincho {
  font-family: ro-shino-std, "Yu Mincho", "YuMincho", "ＭＳ Ｐ明朝", "MS PMincho","ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "serif";
  font-weight: 400;
  font-style: normal;
}

@mixin vdl {
  font-family: vdl-v7mincho, "Yu Mincho", "YuMincho", "ＭＳ Ｐ明朝", "MS PMincho","ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "serif";
  font-weight: 500;
  font-style: normal;
  
}

@mixin gara {
  font-family: garamond-fb-display, "Yu Mincho", "YuMincho", "ＭＳ Ｐ明朝", "MS PMincho","ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "serif";
  font-weight: 400;
  font-style: normal;
}


//font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro","Yu Gothic", YuGothic, "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
    

