@charset "utf-8";

/* # Reset 'Browser standard style'
---------------------------------------------------------------------------- */

body,
h1,h2,h3,h4,h5,h6,
p,ul,ol,dl,dt,dd,li,table,th,th,
form,address,pre,
strong,em,caption,cite,code,dfn,var,
time, small {
	font-size: 100%;
	font-style: normal;
	font-weight: normal;
	margin: 0;
	padding: 0;
	text-decoration: none;
}

img { 
	border: 0; 
	vertical-align: bottom;
}

table { 
	border-collapse: collapse;
	border-spacing: 0;
}

caption, th { text-align: left; }

fieldset { border: 0; }

hr { display: none; }

ol, ul { list-style: none; } 


q:before,q:after { content: ''; } 

abbr,acronym { border: 0; } 

