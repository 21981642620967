@charset "utf-8";

a {
  @include PropertySetPrefix(transition, all 0.3s ease);

  &:link {
    color: $normal-color;
    text-decoration: none;
  }

  &:hover {}

  &:visited {}
}

body {
  font-size: 15px;
  line-height: 1.8;
  letter-spacing: 3px;
  color: $white;
  @include vdl;
  background: $gray;
  overflow-x: hidden;

}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -9999;
  opacity: 0;
  background: $black;
  
  &.is_active {
    opacity: 0.5;
    z-index: 100;
  }
    
}


.layout {
  overflow-x: hidden;
}

p {
  margin-bottom: 1.5em;
}




section {
  @extend .clearfix;
}

.inner {
  width: $pc-width;
  margin: 0 auto;
  display: block;
  position: relative;
}


.narrow-section {
  width: 800px;
  margin: 0 auto;
  display: block;
  position: relative;
}


.form-section {
  padding: 300px 0;
  
  .section-title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  
}

.form-table {
  margin-bottom: 30px;
  th,
  td {
    padding: 20px;
  }
}


.section {
  &-title {
    text-align: center;
    margin-bottom: 50px;

    &-en {
      font-size: 40px;
      @include gara;
      display: block;
      line-height: 1.1;
      margin-bottom: 5px;
    }

    &-ja {
      @include mincho;
      display: block;
      line-height: 1.1;
    }
  }
}



.common-headding {
  font-size: 30px;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 20px;

  &:before,
  &:after {
    @include pseudo;
    height: 3px;
    bottom: 0;
    left: 0;

  }

  &:before {
    width: 150px;
    background: $red;
    z-index: 1;
  }

  &:after {
    right: 0;
    background: $white;
    z-index: 0;
  }
}


.two-column {
  @include flexbox;
  @include justify-content(space-between);

  margin-bottom: 80px;

  &:last-child {
    margin-bottom: 0;
  }

  .row {
    width: 46%;

    &-photo {
      @include box-shadow(45px 45px 0 #8a8a8a);
    }

    p {
      line-height: 2.4;
    }
  }

}




.concept {
  background: url(../img/bg_concept.png) no-repeat 100% 100%;
  background-size: 443px 845px;
  margin-top: -150px;
  padding: 50px 0;

  .section-title {
    margin-bottom: 100px;
  }

  &-catch {
    font-size: 40px;

    &01 {
      display: block;
    }

    &02 {
      display: block;
      text-align: center;
    }
  }


  &-text {
    font-size: 18px;
    line-height: 3;
    width: 65%;
    margin-bottom: 30px;
  }
}


.about {
  background: url(../img/bg_about_pattern.gif) repeat 0 0;
  background-size: 25px;
  position: relative;
  padding: 200px 0;
  color: $normal-color;

  &:before,
  &:after {
    @include pseudo;
    background: url(../img/bg_about.png) no-repeat 0 0;
    background-size: 100% auto;
    width: 100%;
    min-height: 187px;

  }


  &:before {
    top: 0;
    left: 0;
    right: 0;

  }

  &:after {
    bottom: 0;
    left: 0;
    right: 0;

    @include PropertySetPrefix(transform, rotate(180deg));
  }

  &-box {
    .two-column {
      &:nth-child(2n-1) {
        .row {
          &:first-child {
            @include flex-order(1);
          }

          &:last-child {
            @include flex-order(2);
          }
        }
      }

      &:nth-child(2n) {
        .row {
          &:first-child {
            @include flex-order(2);
          }

          &:last-child {
            @include flex-order(1);
          }
        }
      }
    }
  }


  &-company {
    dt {}

    dd {
      margin: -2.8em 0 15px 0;
      padding: 15px 0 15px 100px;
      border-bottom: 1px dotted $white;
      &:last-child {
        border: none;
      }
      ol {
        li {
          list-style: decimal;
          margin-left: 24px;
        }
      }
    }
  }
}



.profile {
  background: $bg-gray;
  color: $normal-color;
  
  .inner {
    background: url(../img/bg_profile.png) no-repeat 50% 0;
    background-size: 100% 24px;
    padding: 120px 0;
  }
  
  &-box {
    .two-column {
      &:nth-child(2n-1) {
        .row {
          &:first-child {
            @include flex-order(2);
          }

          &:last-child {
            @include flex-order(1);
          }
        }
      }

      &:nth-child(2n) {
        .row {
          &:first-child {
            @include flex-order(1);
          }

          &:last-child {
            @include flex-order(2);
          }
          
          
        }
      }
      
      .row {
        &-photo {
          @include box-shadow(45px 45px 0 #dbdbdb);
        }
      }
    }
    
    .common-headding {
      @include mincho;
      
      &:after {
        background: $gray;
      }
      
      .profile-name-en {
        font-size: 15px;
        @include gara;
        display: inline-block;
        vertical-align: middle;
        margin-left: 25px;
      }
    }
    
  }
  
  
  &-history {
    dt {
      
    }
    
    dd {
      line-height: 2.4;
      margin: -2.1em 0 30px 0;
      padding: 0 0 0 80px;
    }
  }
}




.service {
  background: url(../img/bg_service.jpg) no-repeat 50% 50%;
  background-size: cover;
  padding: 80px 0 150px;
  
  &-box {
    ul {
      @include flexbox;
      @include justify-content(space-between);
      
      li {
        width: 30%;
      }
    }
  }
  
  &-name {
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
    @include mincho;
  }
  
}



.gallery {
  padding: 80px 0;

  &-list {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(space-between);

    li {
      width: 30%;
      margin-bottom: 30px;
      text-align:center;


    }

  }

  &-img {
    margin-bottom: 10px;
  }

}



.contact {
  background: $bg-gray;
  color: $normal-color;
  padding: 120px 0;
  
  &-area {
    width: 700px;
    margin: 0 auto;
  }
  
  &-table {
    width: $full-width;
    margin-bottom: 30px;
    th {
      padding: 15px;
      
      vertical-align: middle;
      width: 150px;
    }
    
    td {
      padding: 15px;
      vertical-align: middle;
    }
  }
}


.form-inline {
  @include inline-block;
  li {
    margin-right: 15px;
  }
}


input[type="text"] {
  @include PropertySetPrefix(border-radius, 4px);
  padding: 10px;
  background: $white;
  border: 1px solid $border-gray;
  width: $full-width;
  font-size: 15px;
}

textarea {
  @include PropertySetPrefix(border-radius, 4px);
  padding: 10px;
  background: $white;
  border: 1px solid $border-gray;
  width: $full-width;
  height: 160px;
  font-size: 15px;
}

p.btn {
  text-align: center;
  
  button {
    background: $red;
    color: $white;
    border: none;
    @include PropertySetPrefix(border-radius, 5px);
    padding: 15px 50px;
    font-size: 18px;
    @include vdl;
    line-height: 1.1;
    @include PropertySetPrefix(transition, all 0.3s ease);
    cursor: pointer;
    &:hover {
      background: lighten($red, 20);
    }
  }
  
  &.btn-entry {
    a {
      color: $normal;
      background: $white;
      @include PropertySetPrefix(border-radius, 5px);
      padding: 15px 50px;
      font-size: 18px;
      @include vdl;
      line-height: 1.1;
      @include PropertySetPrefix(transition, all 0.3s ease);
      cursor: pointer;
    }
  }
  
  &.btn-back {
    
    a {
      display: inline-block;
      padding: 10px 30px;
      background: #999999;
      font-size: 14px;
      @include PropertySetPrefix(border-radius, 5px);
      @include vdl;
    line-height: 1.1;
    @include PropertySetPrefix(transition, all 0.3s ease);
    cursor: pointer;
    }
    
    
  }
  
}