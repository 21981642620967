@charset "utf-8";
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?f280a3');
  src:  url('../fonts/icomoon.eot?f280a3#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?f280a3') format('truetype'),
    url('../fonts/icomoon.woff?f280a3') format('woff'),
    url('../fonts/icomoon.svg?f280a3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  @include icon-font;
}


.icon-external:before {
  content: "\e900";
}