@charset "utf-8";

// colors
$normal-color: #454545;

$gray: #282828;
$bg-gray: #e7e7e7;

$normal: #454545;
$black: #000;

$white: #fff;
$red: #c30d21;

$border-gray: #bfbfbf;

$full-width: 100%;
$pc-width: 1200px;




// default
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
dl,
dt,
dd,
form,
img,
strong,
em,
dfn,
span,
address,
small,
time,
section,
header,
footer,
main,
article,
nav,
input,
textarea {
  box-sizing: border-box;
}

img {
  vertical-align: bottom;
  width: $full-width;
}

strong,
.strong {
  
}


// color-set
.red {
  color: #d30749;
}



// text-align
.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

// font-size
.xx-large {
  font-size: 36px;
}

.x-large {
  font-size: 26px;
}

.large {
  font-size: 18px;
}

.normal {
  font-size: 14px;
}

.small {
  font-size: 12px;
}

.x-small {
  font-size: 10px;
}

.xx-small {
  font-size: 9px;
}


// float
.floatRight {
  float: right;
  margin: 0 0 15px 15px;
}

.floatLeft {
  float: left;
  margin: 0 15px 15px 0;
}

.floatRight,
.floatLeft {
  img {
    display: block;
  }
}

// margin-set
.margin-b10 {
  margin-bottom: 10px;
}

.margin-b20 {
  margin-bottom: 20px;
}

.margin-b30 {
  margin-bottom: 30px;
}

.margin-b40 {
  margin-bottom: 40px;
}

.margin-b50 {
  margin-bottom: 50px;
}

// line-height
.line-height12 {
  line-height: 1.2;
}

.line-height14 {
  line-height: 1.4;
}

.line-height16 {
  line-height: 1.6;
}
